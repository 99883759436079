<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Mis Plantillas Pendientes" />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Mis Plantillas Pendientes</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">Listado</h5>
    </b-col>

    <b-col cols="12">
      <b-row
        align-content="center"
        align-h="start"
        align-v="center"
        v-if="wrappedChecklists.length"
      >
        <b-col
          cols="12"
          md="5"
          sm="12"
          class="hp-bg-black-0 hp-bg-dark-100 border hp-border-color-black-10 hp-border-color-dark-80 p-12 mx-5 mb-16"
          style="border-radius: 24px"
          v-for="(item, index) in wrappedChecklists"
          :key="index"
        >
          <large-card
            @click="goToShow(item)"
            :title="item.checklist.name"
            :subtitle="item.project.name"
            :img="item.image"
            :number="item.place.name"
          ></large-card>
        </b-col>
      </b-row>
      <h5 v-else class="text-center mt-16">
        Sin plantillas pendientes actualmente
      </h5>
    </b-col>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import LargeCard from "@/view/components/common/cards/LargeCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    Breadcrumb,
    PageTitle,
    LargeCard,
  },
  mounted() {
    this.getChecklistsUserPending();
  },
  methods: {
    ...mapActions({
      getChecklistsUserPending: "checklist/getChecklistsUserPending",
    }),
    goToShow(item) {
      this.$router.push({
        name: "place-checklist",
        params: {
          id: item.place.id,
          checklistId: item.checklist.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      checklistsUserPending: "checklist/checklistsUserPending",
      currentUser: "auth/currentUser",
    }),
    wrappedChecklists() {
      return this.checklistsUserPending.map((checklist) => {
        return {
          ...checklist,
          image: checklist.image
            ? checklist.image
            : require("@/assets/img/app/checklists/checklist-icon.png"),
        };
      });
    },
  },
};
</script>
